import { Logout } from "../auth/auth";
import { GetAuthEmail, GetJWT, GetJWTref, fitAuthJWT, fitJWTref } from "../cookies/cookies";

export function logoutFetch() {
    fetch(process.env.REACT_APP_API_ROUTE + 'logout/', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + GetJWT()
        }
    }).then((response) => {
        if (response.status === 401) {
            return
        } else {
            return response;
        }
    });
}

export const AuthorizedFetch = (link) => {
    const data = { "email": GetAuthEmail()}
    const Ans = fetch(link, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + GetJWT()
        },
        body: JSON.stringify(data)
    }).then((response) => {
        // console.log(response);
        if (response.status === 401) {
            RefreshToken();
            AuthorizedFetch(link, data);
        } else {
            return response.json();
        }
    }).then((result) => {
        return result
    })
    return Ans
}

export function RefreshToken() {
    RefreshFetch().then((response) => {
        if (response.ok !== true) {
            Logout();
            return
        } else {
           return response.json();
        }
    }).then((result) => {
        if (result) {
            fitAuthJWT(result['access']);
            fitJWTref(result['refresh']);
        } else {
            return result
        }
    });
}

export function RefreshFetch() {
    const Ans = fetch(process.env.REACT_APP_API_ROUTE + 'token/refresh/', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ 'refresh': GetJWTref() })
    })

    return Ans
}

export const changePassFetch = (link, data) => {
    // const data = { "email": GetAuthEmail()}
    const Ans = fetch(link, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + GetJWT()
        },
        body: JSON.stringify(data)
    }).then((response) => {
        // console.log(response);
        if (response.status === 401) {
            RefreshToken();
            AuthorizedFetch(link, data);
        } else {
            return response;
        }
    }).then((result) => {
        return result
    })
    return Ans
}