import React, { useState } from "react";
import { RangeInput } from "../UI/rangeInput/rangeInput";
import "./calculator.scss";

export const Calculator = () => {

    const [sum, setSum] = useState(50000000);
    const [period, setPeriod] = useState(30);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    // console.log(currentYear);
    // console.log(currentMonth);

    const ym = {};
    const cpay = {};
    const cpro = {};

    // Функция для расчета ежемесячного платежа
    function calculatePayment() {
        // Процентная ставка
        const interest = 15;
        // Сотая доля процентной ставки
        const monthlyInterest = interest / 100 / 12;
        // Рассчитываем ежемесячный платеж
        const x = Math.pow(1 + monthlyInterest, period);
        const monthlyPayment = ((sum * x * monthlyInterest) / (x - 1)).toFixed(2);
        // Рассчитываем общую сумму платежа
        // const totalPayment = (monthlyPayment * period).toFixed(2);
        // Рассчитаем сумму переплат
        // const totalInterest = (totalPayment - sum).toFixed(2);

        for (let i = 0; i < period; i++) {
            let ostatok;
            if (i === 0) {
                ostatok = sum;
            } else {
                ostatok = cpay[i-1];
            }
            cpay[i] = (ostatok - (monthlyPayment - (ostatok * monthlyInterest))).toFixed(2);
            cpro[i] = ((ostatok * monthlyInterest) / monthlyPayment * 100).toFixed(2);
        }
        // console.log(cpay);
        // console.log(cpro);
    }

    function showYears() {
        let thisYear = currentYear;
        let thisMonth = currentMonth;
        let month4year = 0;
        for (let i = 1; i <= period; i++) {
            if (thisMonth < 12) {
                thisMonth++;
                month4year++;

            } else if (thisMonth === 12) {
                ym[thisYear] = month4year;
                // addYear(thisYear, month4year);
                thisMonth = 1;
                thisYear++;
                month4year = 1;
            }
        }
        ym[thisYear] = month4year;
        // console.log(ym);
    }
    showYears();
    calculatePayment();

    const years = Object.keys(ym).map((keyName) =>  <div style={{width: (100 / period * ym[keyName]) + '%'}}><span>{keyName}</span></div>);
    const columns = Object.values(cpro).map((item) => <div><div style={{height: item + '%'}}></div></div>)
    // console.log(years);

    // function clickHandle() {
    //     showYears();
    //     calculatePayment();
    // }

    const setPeriodVal = (value) => {
        setPeriod(value);
    }
    const setSumVal = (value) => {
        setSum(value);
    }

    return (
        <form className="calculator">
            <div className="calculator_control">
                <label>
                    <span className="">Желаемая суммая займа</span>
                    <RangeInput
                        metA="₽"
                        metB="₽"
                        MIN={100000}
                        MAX={100000000}
                        step={1000}
                        name='calc_sum'
                        id='calc_sum'
                        callback={setSumVal}
                    />
                </label>
                <label>
                    <span className="">Желаемый срок займа</span>
                    <RangeInput
                        metA="месяц"
                        metB="месяцев"
                        MIN={1}
                        MAX={60}
                        step={1}
                        name='calc_period'
                        id='calc_period'
                        callback={setPeriodVal}
                    />
                </label>
                <label>
                    <span className="">Среднемесячная выручка за последний год </span>
                    <RangeInput
                        metA="₽"
                        metB="₽"
                        MIN={0}
                        MAX={20000000}
                        step={1000}
                        name='calc_sal'
                        id='calc_sal'
                    />
                </label>
            </div>
            <div className="calculator_graph">
                <div className="graph_legend">
                    <span>Основной долг</span>
                    <span>Процент</span>
                </div>
                <div className="graph_columns">
                    {columns}
                </div>
                <div className="graph_years">
                    {years}
                </div>
            </div>
            {/* <button type="button" onClick={clickHandle} className="btn_green">cerffff</button> */}
        </form>
    )
}