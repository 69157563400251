import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../UI/container/container";
import './footer.css';
import Logo from '../../assets/img/Logo.svg';

export const Footer = () => {
    return(
        <footer>
            <Container>
                <a href="/" className="logo"><img src={Logo} alt="Кредиты вместе" className="logo_img"/></a>
                <div className="footer_links-container">
                    <ul>
                        <li><NavLink to="/" className="footer_link">О компании</NavLink></li>
                        <li><NavLink to="/" className="footer_link">Контакты</NavLink></li>
                        <li><NavLink to="/" className="footer_link">Агентам</NavLink></li>
                        <li><NavLink to="/" className="footer_link">Партнерам</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink to="/" className="footer_link">Кредит под исполнение гос. контракта</NavLink></li>
                        <li><NavLink to="/" className="footer_link">Тендерный заем</NavLink></li>
                        <li><NavLink to="/" className="footer_link">Оборотный кредит</NavLink></li>
                        <li><NavLink to="/" className="footer_link">Банковская гарантия</NavLink></li>
                    </ul>
                </div>
                <div className="footer_contacts">
                    <a href="mailto:email@email.ru" className="footer_link">email@email.ru</a>
                    <a href="tel:+79991234567" className="footer_link">+7 999 123 45 67</a>
                    <NavLink to="/политика_конфиденциальности" className="footer_link">Политика конфиденциальности</NavLink>
                </div>
            </Container>
        </footer>
    )
}