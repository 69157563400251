import React from "react";
import { Container } from "../../../components/UI/container/container";
import img1 from "../../../assets/img/illustration/partners_1.png";
import img2 from "../../../assets/img/illustration/partners_2.png";
import img3 from "../../../assets/img/illustration/partners_3.png";
import img4 from "../../../assets/img/illustration/partners_4.png";
import img5 from "../../../assets/img/illustration/partners_5.png";
import "./ugetSection.css";
import { WideBlock } from "../../../components/UI/wideBlock/wideBlock";
import { CardsContainer } from "../../../components/UI/cardsContainer/cardsContainer";

export const UgetSection = () => {
    return (
        <section className="uget_section">
            <Container>
                <h2 className="visually-hidden">Преимущества работы в КредитыВместе</h2>
                <h3 className="uget_h3">С нами вы получаете</h3>
            </Container>
            <WideBlock className="uget_cards-container">
                <CardsContainer>
                    <li className="uget_card">
                        <img src={img1} alt="Качественный поток заявок" />
                        <span>Качественный поток заявок</span>
                    </li>
                    <li className="uget_card">
                        <img src={img2} alt="Работа по всей России" />
                        <span>Работу по всей России</span>
                    </li>
                    <li className="uget_card">
                        <img src={img3} alt="Персональная работа менеджера" />
                        <span>Персональное сопровождение менеджера</span>
                    </li>
                    <li className="uget_card">
                        <img src={img4} alt="Удобство в подключении" />
                        <span>Удобство в подключении</span>
                    </li>
                    <li className="uget_card">
                        <img src={img5} alt="Множество опций продуктов финансирования" />
                        <span>Множество опций продуктов финансирования</span>
                    </li>
                </CardsContainer>
            </WideBlock>
        </section>
    )
}