import React from "react";
import { BecomeSection } from "./becomeSection/becomeSection";
import { UgetSection } from "./ugetSection/ugetSection";
import { HowtoPartnerSection } from "./howtoPartnerSection/howtoPartnerSection";
import { ExpertsSection } from "../home/sectionExperts/expertsSection";
import { BecomePartnerSection } from "./becomePartnerSection/becomePartnerSection";
import { BecomeAgentSection } from "./becomeAgentSection/becomeAgentSection";
import { ToAgentSection } from "./toAgentSection/toAgentSection";
import { HowtoAgentSection } from "./howtoAgentSection/howtoAgentSection";
import "./partners.css";

export const Partners = () => {

    return(
        <main>
            <BecomeSection/>
            <UgetSection/>
            <HowtoPartnerSection/>
            <ExpertsSection/>
            <BecomePartnerSection/>
            <ToAgentSection/>
            <HowtoAgentSection/>
            <BecomeAgentSection/>
        </main>
    )
}