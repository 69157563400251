import React from "react";
import { Container } from "../../components/UI/container/container";
import "./cookies.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export const CookiesMsg = () => {
    const [vis, setVis] = useState(1);

    if (vis !== 1) {
        return
    } else {
        return (
            <div className="cookies_msg">
                <div>
                    <Container>
                        <p>Мы используем <NavLink to="/политика_конфиденциальности">cookies</NavLink>! Это делается для вашего же удобства!</p>
                        <button className="btn_green" onClick={function () { setVis(0) }}>Понятно!</button>
                    </Container>
                </div>
            </div>
        )
    }
}

export const isAuth = () => {
    const value = document.cookie.split("; ").find((row) => row.startsWith("isAuth="))?.split("=")[1];
    if (value === "true") {
        return true
    } else {
        return false
    }
}

export const GetAuthEmail = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("authMail="))?.split("=")[1];
}

export const GetJWT = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("JWT="))?.split("=")[1];
}

export const GetJWTref = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("JWTref="))?.split("=")[1];
}

export function fitAuth(value) {
    const string = "isAuth=" + value;
    document.cookie = string;
}

export function fitAuthMail(value) {
    const string = "authMail=" + value;
    document.cookie = string;
}

export function fitAuthJWT(value) {
    const string = "JWT=" + value;
    document.cookie = string;
}

export function fitJWTref(value) {
    const string = "JWTref=" + value;
    document.cookie = string;
}