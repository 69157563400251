import React from "react";

import './cardAdvantage.css';

export const AdvantageCard = ({span, txt}) => {

    return(
        <li className="advantage_card">
            <span className="card_span">{span}</span>
            <p className="card_txt">{txt}</p>
        </li>
    )
}