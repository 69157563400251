import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/graph_money.svg";
import "./becomeSection.css";

export const BecomeSection = () => {

    return (
        <Section className="become">
            <h2 className="ttl">Станьте нашим <span>партнером</span> уже сегодня</h2>
            <img className="become_img" src={img} alt="" />
            <div className="become_txt">
                <p>
                    Мы цифровая платформа, которая включает в себя все циклы получения финансирования, либо одного из необходимых продуктов для исполнения государственного контракта
                </p>
                <p>
                    Платформа КредитВместе способна обрабатывать ежедневно более 1000 новых обращений
                </p>
            </div>
            <div className="become_garants">
                <h3>Мы гарантируем:</h3>
                <ul>
                    <li className="V-mark">
                        <p>
                            Оперативное рассмотрение новых клиентов, верификация, документооборот
                        </p>
                    </li>
                    <li className="V-mark">
                        <p>
                            Ускоренное рассмотрение заявок по одному или нескольким продуктам клиента
                        </p>
                    </li>
                </ul>
            </div>
        </Section>
    )
}