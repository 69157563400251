import React from 'react';
import { useTimeout } from '../hooks/useTimeout';
import SuccessIcon from "../../assets/img/icons/success-icon.svg";
import FailureIcon from "../../assets/img/icons/error-icon.svg";
import WarningIcon from "../../assets/img/icons/warning-icon.svg";
import InfoIcon from "../../assets/img/icons/info-icon.svg";

export const Toast = (props) => {
//   console.log(props.type);
  useTimeout(props.close, 5000);

  const icon = {
      "success" : SuccessIcon,
      "warning" : WarningIcon,
      "error" : FailureIcon,
      "info" : InfoIcon
  }

  const classes = { 
      "success" : "success",
      "warning" : "warning",
      "error" : "error",
      "info" : "info"
  }

  return (
      <div className={"toast " + classes[props.type]}>
          <button className="toast_close" onClick={props.close}>╳</button>
          <img src={icon[props.type]} className="toast_ico" alt={props.type} />
          <p>{props.children}</p>
      </div>
  );
}