import React from "react";
import { Section } from "../../../components/UI/section/section";
import { OL } from "../../../components/UI/orderedList/orderedList";
import img from "../../../assets/img/illustration/home_2.png";
import './platformSection.css';

export const PlatformSection = () => {
    return (
        <Section className="platform">
            <h2 className="ttl">Как работает платформа «КредитВместе»</h2>
            <OL>
                <li><span>Регистрация</span></li>
                <li><span>Оформление</span></li>
                <li><span>Скорринг</span></li>
                <li><span>Решение</span></li>
                <li><span>Выдача</span></li>
            </OL>
            <img className="platform_img" src={img} alt="" />
        </Section>
    )
}
