import React from "react";
import { NavLink } from "react-router-dom";
import img from "../../assets/img/illustration/404.png";
import { scrollToTop } from "../../features/scrollTop/scrollTop";
import "./404.scss";

export const Page404 = () => {

    return (
        <main className="nf">
            <img src={img} className="" alt="404"/>
            <h1>Ошибка <span>404</span></h1>
            <p>
                К сожалению, запрашиваемая страница не найдена. Возможно, вы перешли по ссылке, в которой была допущена ошибка, 
                или ресурс был удален. Попробуйте перейти на <NavLink to="/" onClick={scrollToTop}>главную страницу</NavLink>
            </p>
        </main>
    )
}