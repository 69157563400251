import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/credits.png";
import "./needSection.css";

export const NeedSection = () => {
    return(
        <Section className="help">
            <h1 className="ttl-1"><span>КредитВместе</span> поможет <span>быстро и удобно</span> получить необходимую сумму</h1>
            <h2 className="ttl-grey">Нужен кредит? Не знаете, с чего начать?</h2>
            <img src={img} className="help_img" alt=""/>
            <h3 className="ttl-3">Мы окажем быструю и качесвенную помощь:</h3>
            <ul className="help_grid">
                <li className="V-mark"><span>Участникам государственных и коммерческих заказов</span></li>
                <li className="V-mark"><span>Бизнесу и предпринимателям</span></li>
                <li className="V-mark"><span>Поставщикам маркетплейсов</span></li>
                <li className="V-mark"><span>Владельцам недвижимости</span></li>
            </ul>
        </Section>
    )
}