import React from "react";

export function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export const scrollTopBtn = () => {
    return(
        <button></button>
    )
}

