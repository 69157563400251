import React from "react";
import bank_logo from "../../../assets/img/bank_logo.png";
import './partnerCard.css';

export const PartnerCard = () => {
    return(
        <li className="partner_card">
            <img className="partner_img" src={bank_logo} alt="bank"/>
            <p className="partner_txt">Ставка от <span className="partner_txt-upper">11%</span></p>
            <p className="partner_txt">Сумма от <span className="partner_txt-upper">100 000₽</span></p>
        </li>
    )
}