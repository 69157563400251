import React from "react";
import { Section } from "../../../components/UI/section/section";
import img1 from "../../../assets/img/illustration/why_1.png";
import img2 from "../../../assets/img/illustration/why_2.png";
import img3 from "../../../assets/img/illustration/why_3.png";
import "./whySection.css";

export const WhySection = () => {
    return (
        <Section className="why">
            <h2 className="ttl">Почему <span>КредитВместе</span>?</h2>
            <ul className="why_cards">
                <li>
                    <section className="why_card">
                        <img src={img1} alt="" className="why_img"/>
                        <h4>Быстрое оказание услуг</h4>
                        <p>
                            Наша компания поможет одобрить кредит в течение 1 дня. Мы автоматизировали наши процессы, 
                            чтобы быстро найти выгодные условия, оформить пакет документов и подать заявку
                        </p>
                    </section>
                </li>
                <li>
                    <section className="why_card">
                        <img src={img2} alt="" className="why_img"/>
                        <h4>Команда проффесионалов</h4>
                        <p>
                            Мы гарантируем полную конфиденциальность и индивидуальный подход к каждому клиенту. 
                            Мы обладаем безупречной репутацией и сотрудничаем с лучшими банками столицы
                        </p>
                    </section>
                </li>
                <li>
                    <section className="why_card">
                        <img src={img3} alt="" className="why_img"/>
                        <h4>Надежные партнеры</h4>
                        <p>
                            Мы сотрудничаем только с надежными банками-партнерами. Мы уверены в том, что наши клиенты получат надежные 
                            и безопасные условия кредитования
                        </p>
                    </section>
                </li>
            </ul>
        </Section>
    )
}