import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/platform1.png";
import "./hero.css";

export const PlatformHero = () => {
    return (
        <Section className="pHero">
            <div className="pHero_txt">
                <h1 className="ttl">Кредит<span>Вместе</span> — надежная платформа цифровых решений для кредитования и получения банковской гарантии</h1>
                <p>
                    Добро пожаловать в мир финансовых возможностей с "КредитВместе"! Мы представляем инновационный финансовый портал, который делает получение необходимых финансовых услуг легким и выгодным для компаний-участниц государственных тендеров,пополнения оборотных средств.
                </p>
            </div>
            <img src={img} className="pHero_img" alt="" />
        </Section>
    )
}