import React from "react";
import { Section } from "../../../components/UI/section/section";
import { FormPartners } from "../../../components/formPartners/formPartners";

export const BecomePartnerSection = () => {
    return(
        <Section>
            <h2 className="ttl">Станьте нашим <span>партнером</span> уже сейчас</h2>
            <FormPartners/>
        </Section>
    )
}