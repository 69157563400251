import React from "react";
import { Section } from "../../../components/UI/section/section";
import { AdvantageCard } from "../../../components/UI/cardAdvantage/cardAdvantage";
import { CardsContainer } from "../../../components/UI/cardsContainer/cardsContainer.js"
import img from "../../../assets/img/illustration/platform5_2.png";
import "./uget.css";

export const PlatformUget = () => {

    return (
        <Section className="pUget">
            <div className="pUget_info">
                <div>
                    <h2>С нами вы получаете</h2>
                    <ul className="pUget_list">
                        <li className="V-mark"><span>Конфиденциальность</span></li>
                        <li className="V-mark"><span>Приоритетное рассмотрение</span></li>
                        <li className="V-mark"><span>Минимальный пакет</span></li>
                        <li className="V-mark"><span>Подробные консультации</span></li>
                        <li className="V-mark"><span>Полностью онлайн</span></li>
                        <li className="V-mark"><span>Удобство в подключении</span></li>
                    </ul>
                </div>
                <img src={img} className="pUget_img" alt="" />
            </div>
            <CardsContainer>
                <AdvantageCard
                    span="6% - 40%"
                    txt="ставка по финансированию годовых в рублях"
                />
                <AdvantageCard
                    span="0% - 7,9%"
                    txt="единоразовая комиссия по кредиту"
                />
                <AdvantageCard
                    span="0.6 мес - 30 лет"
                    txt="срок кредитования"
                />
            </CardsContainer>
        </Section>
    )
}