import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/case.png";
import "./toAgentSection.css";

export const ToAgentSection = () => {

    return(
        <Section className="toAgent">
            <h2>Агентам</h2>
            <div className="toAgent_txt">
            <p>
                Прозрачная и удобная схема сотрудничества с агентами, кредитным брокерами, страховыми агентами, сотрудниками 
                финансовых организаций, бухгалтеров
            </p>
            <p>
                Возможность получать помощь в сопровождении клиента, а также полный контроль за выдачей финансирования клиенту
            </p>
            </div>
            <img className="toAgent_pic" src={img} alt="Финансовым агентам"/>
            <div>
                <h3>Мы предоставляем:</h3>
                <ul>
                    <li className="V-mark"><span>Максимальные условия по комиссии от каждой сделки</span></li>
                    <li className="V-mark"><span>Возможность пассивного сотрудничества с платформой</span></li>
                    <li className="V-mark"><span>Удобный интерфейс</span></li>
                    <li className="V-mark"><span>Персонального менеджера</span></li>
                </ul>
            </div>
        </Section>
    )
}