import React from "react";
import { NavLink } from "react-router-dom";
import { Section } from "../../../components/UI/section/section";
import { AdvantageCard } from "../../../components/UI/cardAdvantage/cardAdvantage";
import { UL } from "../../../components/UI/unorderedList/unorderedList";
import { CardsContainer } from "../../../components/UI/cardsContainer/cardsContainer";
import img from "../../../assets/img/illustration/home_3.png";
import './expertsSection.css';

export const ExpertsSection = () => {
    return (
        <Section className="experts">
            <h2 className="ttl">Команда лучших экспертов</h2>
            <div className="experts_info">
                <p>
                    Мы гарантируем полную <NavLink to="/политика_конфиденциальности">конфиденциальность</NavLink> и индивидуальный подход к каждому клиенту
                </p>
                <p>
                    Мы обладаем <span>безупречной</span> репутацией и сотрудничаем с лучшими банками столицы
                </p>
            </div>
            <CardsContainer className="experts_advs">
                <AdvantageCard
                    span="150 млрд+"
                    txt="сумма одобренных заявок на финансирование"
                />
                <AdvantageCard
                    span="4000+"
                    txt="компаний воспользовались нашим сервисом и остались довольны"
                />
                <AdvantageCard
                    span="10+"
                    txt="доступно опций финансирования продуктов"
                />
            </CardsContainer>
            <div className="experts_lists">
                <section>
                    <UL>
                        <h3 className="ttl-3">Финансирование бизнеса</h3>
                        <li><span>Онлайн подача заявки</span></li>
                        <li><span>Выгодное получение кредита</span></li>
                        <li><span>Комфортный срок рассмотрения заявки</span></li>
                    </UL>
                </section>
                <section>
                    <UL>
                        <h3 className="ttl-3">Банковская Гарантия</h3>
                        <li><span>Удобное оформление заявки</span></li>
                        <li><span>Рассмотрение ТОП – Банков РФ</span></li>
                        <li><span>Комфортный срок рассмотрения заявки</span></li>
                    </UL>
                </section>
            </div>
            <img src={img}  className="experts_img"/>
        </Section>
    )
}