import React from "react";
import { Navigate } from "react-router";
import { CabinetInfo } from "./cabinetInfo/cabinetInfo";
import { isAuth } from "../../features/cookies/cookies";
import { UserBids } from "./userBids/userBids";
import { PassPopup } from "./passPopup/passPopup";

export const Cabinet = () => {
    if (!isAuth()) {
        return(<Navigate to="/"/>)
    } else {
        return(
            <>
                <main>
                    <CabinetInfo />
                    <UserBids />
                </main>
                <PassPopup />
            </>
        )
    }
}