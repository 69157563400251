import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './features/auth/auth';
import { ToastProvider } from './features/Toast/ToastProvider';
import { CookiesMsg, fitAuth } from './features/cookies/cookies';
import { RefreshFetch } from './features/fetches/fetches.js';
import { Header } from './components/header/header';
import { Footer } from './components/footer/footer';
import { PopupRecall } from './components/popupRecall/popupRecall.js';
import { Home } from './pages/home/home';
import { Credits } from './pages/credits/credits';
import { Garants } from './pages/garants/garants';
import { Partners } from './pages/partners/partners';
import { Platform } from './pages/platform/platform';
import { Confidentiality } from './pages/confidentiality/confidentiality';
import { Page404 } from './pages/404/404';
import { Cabinet } from './pages/cabinet/cabinet';
import { PopupLogin } from './components/popupLogin/popupLogin';

import './App.scss';

const App = () => {
  function checkToken() {
    RefreshFetch().then((response) => {
      // console.log(response);
      if (response.ok !== true) {
        fitAuth(false);
      }
    })
  }
  checkToken();

  return (
    <AuthProvider>
      <ToastProvider>
        <Router>
          <Header />
          <CookiesMsg />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/кредиты" element={<Credits />} />
            <Route path="/гарантии" element={<Garants />} />
            <Route path="/партнерам" element={<Partners />} />
            <Route path="/о_платформе" element={<Platform />} />
            <Route path="/личный_кабинет" element={<Cabinet />} />
            <Route path="/политика_конфиденциальности" element={<Confidentiality />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
          <Footer />
          <PopupLogin />
          <PopupRecall />
        </Router>
      </ToastProvider>
    </AuthProvider>
  )
}

export default App;