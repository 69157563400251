import React from "react";
import { NavLink } from "react-router-dom";
import { Section } from "../../../components/UI/section/section";
import { UL } from "../../../components/UI/unorderedList/unorderedList";
import img from "../../../assets/img/illustration/garants_2.png"
import "./bannerSection.css";
import { scrollToTop } from "../../../features/scrollTop/scrollTop";

export const BannerSection = () => {
    return(
        <Section className="banner">
            <h2 className="ttl">Так же мы предоставляем услуги по получению <span>кредита</span> на самых выгодных условиях</h2>
            <div className="banner_txt">
                <UL>
                    <li><span>Удобное оформление заявки</span></li>
                    <li><span>Рассмотрение ТОП – Банков РФ</span></li>
                    <li><span>Комфортный срок рассмотрения заявки</span></li>
                </UL>
                <UL>
                    <li><span>Полная конфиденциальность</span></li>
                    <li><span>Индивидуальный подход к каждому клиенту</span></li>
                    <li><span>Более 4000 довольных клиентов</span></li>
                </UL>
            </div>
            <NavLink to="/кредиты" className="btn_green" onClick={scrollToTop}>Подробнее</NavLink>
            <img src={img} alt="" className="banner_img"/>
        </Section>
    )
}