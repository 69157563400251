import React from "react";
import "./cardsContainer.css";

export const CardsContainer = ( { children } ) => {

    return(
        <ul className="cards_container">
            {children}
        </ul>
    )
}