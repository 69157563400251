import React from "react";
import { Section } from "../../../components/UI/section/section";
import { ApplicationForm } from "../../../components/formApplication/applicationForm";
import img from '../../../assets/img/illustration/home.png'
import './heroSection.css';

export const HeroSection = () => {
    return (
        <Section className="hero">
            <h1 className="ttl-1">Поможем быстро получить кредит на максимально <span>выгодных</span> условиях</h1>
            <ApplicationForm />
            <img src={img} alt="Заявка на кредит онлайн" className="hero_img"/>
        </Section>
    )
}