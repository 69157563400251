import React, { useEffect, useState } from "react";
import { Section } from "../../../components/UI/section/section";
import { GetAuthEmail } from "../../../features/cookies/cookies";
import { Spinner } from "../../../components/UI/spinner/spinner";
import { AuthorizedFetch } from "../../../features/fetches/fetches";

import "./userBids.scss";
import { togglePopup } from "../../../components/popup/popup";

export const UserBids = () => {
    // console.log('Rendered');
    const [Bids, setBids] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const link = process.env.REACT_APP_API_ROUTE + 'user-statuses/';
        (async () => {
            if (!isLoading) {
                AuthorizedFetch(link).then((result) => setBids(result));
                // console.log(Bids);
                setIsLoading(true);
            }
        })();
    }, [isLoading]);

    const BidsList = Bids.map(el => {
        const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{6})Z$/;
        const match = regex.exec(el.created_at);
        const dt = match[3] + "." + match[2] + "." + match[1] + ' в ' + match[4] + ":" + match[5];

        return (
            <li key={el.id} className="bid">
                <h3>Заявка {el.id}</h3>
                <span>Создана: {dt}</span>
                <span>Тема: {el.theme}</span>
                <span>Статус: {el.status}</span>
            </li>
        )
    });

    // const BidsList = if(Bids) {Bids.map(el => {
    //     const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{6})Z$/;
    //     const match = regex.exec(el.created_at);
    //     const dt = match[3] + "." + match[2] + "." + match[1] + ' в ' + match[4] + ":" + match[5];

    //     return (
    //         <li key={el.id} className="bid">
    //             <h3>Заявка {el.id}</h3>
    //             <span>Создана: {dt}</span>
    //             <span>Тема: {el.theme}</span>
    //             <span>Статус: {el.status}</span>
    //         </li>
    //     )
    // });} else {return(<h3>Вы не оставили ни одной заявки!</h3>)}

    function handler() {
        togglePopup('recall');
    }
    
    return (
        <Section className="lkBids">
            <div className="liBids_row">
                <h2>Ваши заявки</h2>
                <button className="btn_green" onClick={handler}>Создать</button>
            </div>
            {isLoading ? <ul className="lkBids_list">{BidsList}</ul> : <Spinner />}
            {/* <Spinner /> */}
        </Section>
    )
}