import React, { useState } from "react";
import { useNavigate } from "react-router";
import { fitAuth, fitAuthJWT, fitAuthMail, fitJWTref, isAuth } from "../../features/cookies/cookies";
import { PopUp, togglePopup } from "../popup/popup";
import { serializeFD } from "../../features/forms/forms";
import { useToast } from "../../features/Toast/useToast";
import { useAuth } from "../../features/auth/auth";

import "./popupLogin.css";
import { Spinner } from "../UI/spinner/spinner";

export const PopupLogin = () => {
    const [Auth, setAuth] = useAuth();
    const [Loaded, setLoaded] = useState(true);
    const Navigate = useNavigate();

    const toast = useToast();
    const successToast = () => toast.open(`Авторизация успешна!`, "success");
    const errorToastDiff = () => toast.open(`Ошибка! Неверный пароль!`, "error");

    async function loginHandler(e) {
        e.preventDefault()
        // e.stopPropagation();
        const link = process.env.REACT_APP_API_ROUTE + 'login/';
        const form = e.target;
        const fdata = new FormData(form);
        const data = serializeFD(fdata);

        function toggler() {
            form.querySelectorAll('input').forEach(element => {
                element.classList.toggle('invalid');
            });
        }
        fetch(link, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
            .then((response) => {
                // console.log(response);
                setLoaded(false);
                if (response['ok']) {
                    successToast();
                    togglePopup('login');
                    fitAuth(true);
                    setAuth(true);
                    setLoaded(true)
                } else {
                    errorToastDiff();
                    toggler();
                    setTimeout(2000, toggler);
                    setLoaded(true);
                    return 0
                }
                return response.json();
            })
            .then((result) => {
                if (result) {
                    // console.log(result['access']);
                    // console.log(result['refresh']);
                    fitAuthJWT(result['access']);
                    fitJWTref(result['refresh']);
                    fitAuthMail(data['email']);
                    Navigate("/личный_кабинет");
                }
            });
    }

    if (isAuth() && Auth) {
        return
    } else {
        return (
            <PopUp ids="login">
                <h2>Авторизация</h2>
                {Loaded ? 
                <form className="logForm" onSubmit={loginHandler}>
                    <label>
                        <span className="visually-hidden">Поле ввода логина</span>
                        <input className="input_text" placeholder="email" type="email" name="email" required />
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода пароля</span>
                        <input className="input_text" placeholder="пароль" type="password" name="password" required />
                    </label>
                    <button className="btn_green">Войти</button>
                </form>
                : <Spinner />}
            </PopUp>
        )
    }
}