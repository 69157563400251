import React from "react";
import { Section } from "../../../components/UI/section/section";
import { UL } from "../../../components/UI/unorderedList/unorderedList";
import "./garantsInfoSection.css";

export const GarantsInfoSection = () => {
    return (
        <Section className="garants_info">
            <div className="row">
                <h2 className="ttl">Виды банковской гарантии</h2>
                <div>
                    <a className="btn_green" href="#fz44">44-ФЗ</a>
                    <a className="btn_green" href="#fz223">223-ФЗ</a>
                    <a className="btn_green" href="#fz185">185-ФЗ</a>
                </div>
            </div>
            <ul className="info_containers">
                <li>
                    <section id="fz44">
                        <h3 className="ttl-3-g">Банковская гарантия 44-ФЗ</h3>
                        <div className="info_container">
                            <div>
                                <p className="mb-1">
                                    Банковская гарантия 44 ФЗ – обеспечивает исполнение договора с вашими контрагентами. Процедура выдачи
                                    банковской гарантии данного типа предусматривает выдачу банком письменного обязательства, в котором будет
                                    указано, что финансовое учреждение компенсирует заказчику указанные суммы, если вами будут нарушены
                                    условия контракта в срок.
                                </p>
                                <p>
                                    Банки выдающие банковские гарантии по 44 фз работают исключительно в рамках действующего законодательства.
                                </p>
                            </div>
                            <div>
                                <span className="ttl-4">Гарантии предоставляются:</span>
                                <UL>
                                    <li><span>Отчет о финансовой деятельности вашего предприятия</span></li>
                                    <li><span>Учредительную документацию (Устав предприятия, ИНН, ОГРН и др.)</span></li>
                                    <li><span>Ранее исполненные договора</span></li>
                                </UL>
                            </div>
                            <div>
                                <p>
                                    Наиболее подробный список требуемых документов должны предоставить банки, в которых вы планируете
                                    получить банковские гарантии. При подаче документов онлайн, на них ставится квалифицированная электронная
                                    подпись.
                                </p>
                            </div>
                        </div>
                    </section>
                </li>
                <li>
                    <section id="fz223">
                        <h3 className="ttl-3-g">Банковская гарантия 223-ФЗ</h3>
                        <div className="info_container">
                            <div>
                                <p className="mb-1">
                                    Что обеспечивает банковская гарантия по 223 ФЗ – исполнение обязательств по договору в общем понимании.
                                    В обстоятельствах, при которых исполнитель не может выполнить свои обязательства и даже отказывается от их
                                    исполнения, он предлагает заказчику обратиться в банк и получить банковскую гарантию по 223 ФЗ в качестве
                                    компенсации за нарушение условий договоренностей.
                                </p>
                                <p>
                                    К регулирующим документам в данном случае также следует отнести Закон “О закупках товаров, работ”.
                                    В нем говорится, что заказчики имеют право на самостоятельное формирование Положения о закупках для
                                    своих предприятий.
                                </p>
                            </div>
                            <div>
                                <span className="ttl-4">Гарантии предоставляются:</span>
                                <UL>
                                    <li><span>При участии в тендере</span></li>
                                    <li><span>При возврате авансового платежа</span></li>
                                    <li><span>При обеспечении государственного контракта</span></li>
                                </UL>
                            </div>
                            <div>
                                <p className="mb-1">
                                    Обеспечение банковской гарантии 223 ФЗ – это задача банков, с которыми исполнители заключают контракты.
                                    При этом заказчик сам установит срок и порядок заключения договора, в том числе способы и форматы закупки
                                    товара.
                                </p>
                                <p>
                                    Срок действия банковской гарантии по 223 ФЗ при обеспечении обязательств по договору должен превышать
                                    как минимум 30 дневный период, наступающий с момента окончания действия контракта. В других случаях он
                                    может быть превышен на 2-3 месяца. Обратитесь к нашим специалистам, чтобы заказать услугу срочного
                                    оформления банковской гарантии 223 ФЗ.
                                </p>
                            </div>
                        </div>
                    </section>
                </li>
                <li>
                    <section id="fz185">
                        <h3 className="ttl-3-g">Банковская гарантия 185-ФЗ (615-ПП)</h3>
                        <div className="info_container">
                            <div>
                                <p className="mb-1">
                                    Банковская гарантия по 185-ФЗ – это услуга банка, предоставляемая при заключения контракта с юридическим лицом, оказывающим услугикапитального ремонта многоквартирного дома.
                                </p>
                                <p>
                                    Получить банковскую гарантию по 185-ФЗ можно исключительно в банках из специального списка, регламентированного требованиями Налогового кодекса России. Такие виды работ получают финансирование с банковских счетов региональных фондов модернизации ЖКХ. Также на них предусматриваются дотации из государственного бюджета.
                                </p>
                            </div>
                            <div>
                                <span className="ttl-4">Гарантии предоставляются:</span>
                                <UL>
                                    <li><span>При ремонте культурных объектов</span></li>
                                    <li><span>При замене лифтов и лифтовых шахт</span></li>
                                    <li><span>На оценку техсостояния и разработку проекта капитального ремонта на объектах, причисленных к культурному наследию</span></li>
                                </UL>
                            </div>
                            <div className="pt-3">
                                <UL>
                                    <li><span>При оценке соответствия состояния лифтов техническим регламентам Таможенного союза</span></li>
                                    <li><span>В период осуществления строительного контроля</span></li>
                                </UL>
                            </div>
                        </div>
                    </section>
                </li>
            </ul>
        </Section>
    )
}