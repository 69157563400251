import React from "react";
import { Section } from "../../../components/UI/section/section";
import { OL } from "../../../components/UI/orderedList/orderedList";
import "./howtoAgentSection.css";

export const HowtoAgentSection = () => {

    return(
        <Section className="htAgent">
            <h2 className="ttl">Как стать агентом ?</h2>
            <OL>
                <li><span>Заполнить заявку</span></li>
                <li><span>Знакомство с менеджером</span></li>
                <li><span>Ускоренное подписание договора сотрудничества</span></li>
                <li><span>Доступ в личный кабинет</span></li>
            </OL>
        </Section>
    )
}