import React from "react";
import { Section } from "../../../components/UI/section/section";
import { ApplicationForm } from "../../../components/formApplication/applicationForm";

export const FeedbackSection = () => {

    return(
        <Section>
            <h2 className="ttl">Сделайте <span>первый шаг</span> уже сейчас</h2>
            <ApplicationForm />
        </Section>
    )
}