import React from "react";
// import { NavLink } from "react-router-dom";
import { Form } from "../UI/form/form";
import { Select } from "../UI/select/select";
import { validateEmail, validateINN, validateName, validatePhone } from "../../features/validator/validator";

export const FormFeedback = () => {

    // const sendForm = (e) => {
    //     e.preventDefault();
    //     console.log('send')
    // }

    return (
        <Form  notSend="false">
            <div className="form_column">
                <div className="form_row">
                    <label>
                        <span className="visually-hidden">Поле ввода ФИО</span>
                        <input className="input_text" type="text" name="fio" placeholder="ФИО" required/>
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода ИНН</span>
                        <input className="input_text" type="text" name="inn" placeholder="ИНН" required/>
                    </label>
                </div>
                <div className="form_row">
                    <label>
                        <span className="visually-hidden">Поле ввода электронной почты</span>
                        <input className="input_text" type="email" name="email" placeholder="E-mail" required/>
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода телефона</span>
                        <input className="input_text" type="phone" name="phone" placeholder="Телефон" required/>
                    </label>
                </div>
                <label>
                    <span className="visually-hidden">Поле выбора типа партнёрства</span>
                    <Select name="theme">
                        <option value="">Что вас интересует?</option>
                        <option value="Кредит">Кредит</option>
                        <option value="Банковская гарантия">Банковская гарантия</option>
                        <option value="Сотрудничество">Сотрудничество</option>
                    </Select>
                </label>
            </div>
        </Form>
    )
}