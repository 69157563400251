import React from "react";
import { NeedSection } from "./needSection/needSection";
import { HowSection } from "./howSection/howSection";
import { WhySection } from "./whySection/whySection";
import { PartnersSection } from "../../components/partners/partnersSection";
import { StepSection } from "./stepSection/stepSection";

export const Credits = () => {

    return(
        <main>
            <NeedSection />
            <HowSection />
            <WhySection />
            <PartnersSection />
            <StepSection />
        </main>
    )
}