import React from "react";
import { GarantsSection } from "./garantsSection/garantsSection";
import { GarantsInfoSection } from "./garantsInfoSection/garantsInfoSection";
import { ProcedureSection } from "./procedureSection/procedureSection";
import { BannerSection } from "./bannerSection/bannerSection";

export const Garants = () => {

    return(
        <main>
            <GarantsSection />
            <GarantsInfoSection />
            <ProcedureSection />
            <BannerSection />
        </main>
    )
}