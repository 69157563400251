import React from "react";
import { Section } from "../../../components/UI/section/section";
import { OL } from "../../../components/UI/orderedList/orderedList";
import "./howtoPartnerSection.scss";

export const HowtoPartnerSection = () => {

    return(
        <Section className="htPartner">
            <h2 className="ttl">Как стать партнером ?</h2>
            <OL>
                <li><span>Заполнить заявку</span></li>
                <li><span>Знакомство с менеджером</span></li>
                <li><span>Согласовываем условия сотрудничества</span></li>
                <li><span>Подготовка и настройка всех программ</span></li>
                <li><span>Запуск сотрудничества</span></li>
            </OL>
        </Section>
    )
}