import React, { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { ToastContext } from './ToastContext';
import { Toast } from './Toast';
import "./Toast.scss";

// Create a random ID
function generateUEID() {
  var random = Math.random().toString(36).substr(2, 10);

  return random;
}

export const ToastProvider = (props) => {
  const [toasts, setToasts] = useState([]);

  const open = (content, type) =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateUEID(), content, type },
    ]);

  const close = (id) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <div className='container' id='toasterZone'>
          <div className="toasts-wrapper" id='toastBox'>
          {toasts.map((toast) => (
            <Toast key={toast.id} close={() => close(toast.id)} type={toast.type}>
              {toast.content}
            </Toast>
          ))}
        </div>
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};
