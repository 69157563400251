import React from "react";
import { RangeInput } from "../UI/rangeInput/rangeInput";
import { Form } from "../UI/form/form";

export const ApplicationForm = () => {

    return (
        <Form className="feedback_form" notSend="false">
            <div className="form_column">
                <div className="form_row">
                    <label>
                        <span className="visually-hidden">Поле ввода ФИО</span>
                        <input name="fio" className="input_text" placeholder="ФИО" required/>
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода ИНН</span>
                        <input name="inn" className="input_text" placeholder="ИНН" required/>
                    </label>
                </div>
                <div className="form_row">
                    <label>
                        <span className="visually-hidden">Поле ввода телефона</span>
                        <input name="phone" className="input_text" placeholder="Телефон" type="tel" required/>
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода email</span>
                        <input name="email" className="input_text" placeholder="E-mail" type="email" required/>
                    </label>
                </div>
                <div className="form_row">
                    <label>
                        <span className="">Желаемая суммая займа</span>
                        <RangeInput
                            metA="₽"
                            metB="₽"
                            MIN={100000}
                            MAX={10000000}
                            step={1}
                            name='sum'
                        />
                    </label>
                    <label>
                        <span className="">Желаемый срок займа</span>
                        <RangeInput
                            metA="месяц"
                            metB="месяцев"
                            MIN={1}
                            MAX={36}
                            step={1}
                            name='period'
                        />
                    </label>
                </div>
            </div>
        </Form>
    )
}