import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/platform3.png";
import "./team.css";

export const PlatformTeam = () => {

    return (
        <Section className="pTeam">
            <div className="pTeam_info">
                <h2><span>Опытная</span> команда</h2>
                <div className="pTeam_txt">
                    <div>
                        <p>Мы, команда "КредитВместе", основанная на многолетнем опыте работы с участниками рынка госзаказов, с гордостью предлагаем только лучшие финансовые продукты нашим клиентам</p>
                        <p>Мы заботимся о вашем успехе и делаем наши решения доступными и удобными для каждого клиента</p>
                    </div>
                    <div>
                        <p>Кроме того, "КредитВместе" готов помочь вам с оформлением кредитных лимитов не только на все наши продукты, но и для других корпоративных клиентов</p>
                        <p>Доверьтесь нам и получите оптимальные решения для развития вашего бизнеса</p>
                        <p>Приходите к нам в "КредитВместе" и откройте двери в финансовое будущее уже сегодня</p>
                    </div>
                </div>
            </div>
            <img src={img} className="pTeam_img" alt="" />
        </Section>
    )
}