import React from "react";
import { Section } from "../UI/section/section";
import { PartnerCard } from "../UI/cardPartner/partnerCard";
import './partnersSection.css';

export const PartnersSection = () => {
    
    let numberElements = 4;
    let numberViewed = 4;
    let currentPosition = 1;

    function countLi() {
        return document.querySelectorAll(".partners_container li").length;
    }

    function setSliderPosition(n1, count) {
        if (n1 < 1) {
            setSliderPosition(count - (numberViewed - 1) + n1, count);
            return;
        }
        n1 = ((n1 - 1) % (count - (numberViewed - 1))) + 1;
        currentPosition = n1;

        const liElements = document.querySelectorAll(".partners_container li");
        liElements.forEach(function (li, index) {
            li.style.left = -((n1 - 1) * li.offsetWidth) + "px";
        });
    }

    function slideLeft() {
        if (countLi() > numberViewed) {
            if (currentPosition === 1) {
                const liElements = document.querySelectorAll(".partners_container li");
                liElements.forEach(function (li) {
                    li.style.transition = "none";
                });
                setSliderPosition(countLi() - (2 * numberViewed + 1), countLi());
                liElements.forEach(function (li) {
                    li.style.transition = "left .75s ease-out";
                });
                return setSliderPosition(currentPosition - 1, countLi());
            } else {
                return setSliderPosition(currentPosition - 1, countLi());
            }
        }
    }

    function slideRight() {
        if (countLi() > numberViewed) {
            if (currentPosition === countLi() - (numberViewed - 1)) {
                const liElements = document.querySelectorAll(".partners_container li");
                liElements.forEach(function (li) {
                    li.style.visibility = "hidden";
                    li.style.transition = "none";
                });
                setSliderPosition(numberViewed + 1, countLi());
                liElements.forEach(function (li) {
                    li.style.transition = "left .75s ease-out";
                    li.style.visibility = "visible";
                });
                return;
            } else {
                return setSliderPosition(currentPosition + 1, countLi());
            }
        }
    }

    function initWidths() {
        const liElements = document.querySelectorAll(".partners_container li");
        liElements.forEach(function (li) {
            li.style.width = 100 / countLi() + "%";
        });
        const ulElement = document.querySelector(".partners_container");
        ulElement.style.width = 100 + (countLi() - numberViewed) * (100 / numberViewed) + "%";
    }

    function initClones() {
        if (countLi() >= numberViewed) {
            for (let i = 0; i < numberViewed; i++) {
                const clonedLi = document.querySelector(".partners_container li:nth-child(" + (i + 1) + ")").cloneNode(true);
                document.querySelector(".partners_container").appendChild(clonedLi);
            }
            const c = countLi();
            for (let i = c - numberViewed; i < c; i++) {
                const clonedLi = document.querySelector(".partners_container li:nth-last-child(" + (i + 1) + ")").cloneNode(true);
                document.querySelector(".partners_container").prepend(clonedLi);
            }
        }
    }

    function initSlider() {
        initClones();
        initWidths();
        initPosition();
        initResponsive();
    }

    function initPosition() {
        setSliderPosition(currentPosition, countLi());
    }

    function initResponsive() {
        if (window.innerWidth > 1000) {
            if (numberViewed !== 4) {
                numberViewed = 4;
                initWidths();
            }
        } else if (window.innerWidth > 800) {
            if (numberViewed !== 3) {
                numberViewed = 3;
                initWidths();
            }
        } else if (numberViewed !== 2) {
            numberViewed = 2;
            initWidths();
        }
        initPosition();
    }

    // initSlider();
    window.addEventListener("resize", initResponsive);

    return (
        <Section className='partners'>
            <h2 className="ttl">Наши партнеры</h2>
            <div className="partners_slider">
                <ul className="partners_container">
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                    <PartnerCard />
                </ul>
            </div>
            <div className="slider_btns">
                <button className="slider_btn-prev" onClick={slideLeft}>
                    <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.5 9H1.5" stroke="#626262" stroke-linecap="round" />
                        <path d="M9 1L1 9L9 17" stroke="#626262" stroke-linecap="round" />
                    </svg>
                </button>
                <button className="slider_btn-next" onClick={slideRight}>
                    <svg width="33" height="18" viewBox="0 0 33 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 9H31" stroke="#626262" stroke-linecap="round" />
                        <path d="M23.5 1L31.5 9L23.5 17" stroke="#626262" stroke-linecap="round" />
                    </svg>
                </button>
            </div>
        </Section>
    )
}