import React from "react";
import { Section } from "../../../components/UI/section/section";
import { FormPartners } from "../../../components/formPartners/formPartners";
import "./becomeAgentSection.css";

export const BecomeAgentSection = () => {
    return(
        <Section className="becomeAgent">
            <h2 className="ttl">Станьте нашим <span>агентом</span> уже сейчас</h2>
            <FormPartners/>
        </Section>
    )
}