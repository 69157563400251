import React from "react";
import { NavLink } from "react-router-dom";
import { useToast } from "../../../features/Toast/useToast";
import "./form.css";
import { validateEmail, validateINN, validateName, validatePhone } from "../../../features/validator/validator";

export const Form = ({ children, className, notSend }) => {

    const toast = useToast();
    const successToast = () => toast.open(`Форма отправлена успешно!`, "success");
    const errorToast = () => toast.open(`Форма не отправлена!`, "error");
    const warningToast = () => toast.open(`Необходимо дать согласие на обработку!`, "warning");

    const route = process.env.REACT_APP_API_ROUTE + 'customers/';

    async function sendData(data, url) {
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
        });
        return response;
    }

    function serialize(data) {
        let obj = {};
        for (let [key, value] of data) {
            if (obj[key] !== undefined) {
                if (!Array.isArray(obj[key])) {
                    obj[key] = [obj[key]];
                }
                obj[key].push(value);
            } else {
                obj[key] = value;
            }
        }
        return obj;
    }

    function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const fdata = new FormData(form);
        const data = serialize(fdata);
        const label = form.querySelector(".form_approve-span");
        const name = form.querySelector('input[name="fio"]');
        const inn = form.querySelector('input[name="inn"]');
        const phone = form.querySelector('input[name="phone"]');
        const email = form.querySelector('input[name="email"]');
        validateEmail(email);
        validateINN(inn);
        validateName(name);
        validatePhone(phone);
        if (validateEmail(email) && validateINN(inn) && validateName(name) && validatePhone(phone)) {
            if (data["agree"] === "on" && notSend !== "true") {
                sendData(data, route).then((response) => {
                    if (response.ok !== true) {
                        errorToast();
                    } else {
                        successToast();
                        name.value = ''
                        inn.value = ''
                        email.value = ''
                        phone.value = ''
                    }
                    return
                });
            } else if (data["agree"] === "on") {
                successToast();
            } else {
                function toggler() {
                    form.classList.toggle("shake");
                    label.classList.toggle("warn");
                }
                toggler();
                errorToast();
                warningToast();
                setTimeout(toggler, 1000);
            }
        }
    }

    // const submit = {callback}
    return (
        <form className={className} onSubmit={handleSubmit}>
            {children}
            <div className="form_approve">
                <label className="approve_check">
                    <span className="form_approve-span">Даю согласие на обработку <NavLink to="/политика_конфиденциальности">персональных данных</NavLink></span>
                    <input type="checkbox" name="agree" />
                </label>
                <button type="submit" className="btn_green">Отправить в рассмотрение</button>
            </div>
        </form>
    )
}