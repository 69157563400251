import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/platform2.png";
import "./banks.css";

export const PlatformBanks = () => {

    return (
        <Section className="pBanks">
            <div className="pBanks_txt">
                <h2>Более <span>20 банков</span> доступны вам прямо сейчас</h2>
                <div>
                    <p>Наше портальное решение — это современный агрегатор финансовых услуг, который предоставляет быстрый и удобный доступ к финансовым ресурсам более 20 банков </p>
                    <p>Забудьте о сложностях и долгих процедурах! У нас вы сможете легко выбрать оптимальные условия из множества вариантов. Благодаря уровню автоматизации, заявка, проверка и получение решения занимают минимальное время</p>
                </div>
            </div>
            <img src={img} className="pBanks_img" alt="" />
        </Section>
    )
}