import React from "react";
import { Section } from "../../../components/UI/section/section";
import { OL } from "../../../components/UI/orderedList/orderedList";
import "./procedureSection.css";

export const ProcedureSection = () => {
    return(
        <Section>
            <h2 className="ttl">Процедура получения банковской гарантии</h2>
            <OL>
                <li><span>Регистрация</span></li>
                <li><span>Загрузить минимальный пакет</span></li>
                <li><span>Решение</span></li>
            </OL>
        </Section>
    )
}