import React, { useState } from "react";
import './rangeInput.scss';

// export function RangeInput({ props }) {
export const RangeInput = ({ callback, id, MIN, MAX, metA, metB, step, name }) => {
    const [value, setValue] = useState(MAX / 2);
    const [metrika, setMetrika] = useState(metB);
    const [width, setWidth] = useState(value / MAX * 100 - 3);
    const handleChange = (e) => {
        e.preventDefault();
        setValue(e.target.value);
        setWidth(value /MAX * 100 - 3);
        if (e.target.value > 1) {
            setMetrika(metB);
        } else {
            setMetrika(metA);
        }
        callback ? callback(e.target.value) : console.log();
    }
    
    return (
        <div className="input-range">
            <span className="input-range_output">{value} {metrika}</span>
            <input 
                type="range" 
                min={MIN} 
                max={MAX} 
                value={value} 
                step={step} 
                name={name}
                id={id}
                onChange={handleChange}
                className="slider"
            />
            <div className="progress" style={{width: width + "%"}}></div>
            <div className="input-range_diapazone">
                <span>{MIN} {metA}</span>
                <span>{MAX} {metB}</span>
            </div>
        </div>
    )
}