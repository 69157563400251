import React from "react";
import { Form } from "../UI/form/form";
import { Select } from "../UI/select/select";
import { validateEmail, validateINN, validateName, validatePhone } from "../../features/validator/validator";

export const FormPartners = () => {

    return (
        <Form  notSend="false">
            <div className="form_column">
                <div className="form_row">
                    <label>
                        <span className="visually-hidden">Поле ввода ФИО</span>
                        <input className="input_text" type="text" name="fio" placeholder="ФИО" required/>
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода ИНН</span>
                        <input className="input_text" type="text" name="inn" placeholder="ИНН" required/>
                    </label>
                </div>
                <div className="form_row">
                    <label>
                        <span className="visually-hidden">Поле ввода телефона</span>
                        <input className="input_text" type="phone" name="phone" placeholder="Телефон" required/>
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода электронной почты</span>
                        <input className="input_text" type="email" name="email" placeholder="E-mail" required/>
                    </label>
                </div>
            </div>
            <label>
                <span className="visually-hidden">Поле выбора типа партнёрства</span>
                <Select name="theme">
                    <option value="">Тип партнерства</option>
                    <option value="Банк">Банк</option>
                    <option value="Агент">Агент</option>
                    <option value="Финансовая организация">Финансовая организация</option>
                </Select>
            </label>
        </Form>
    )
}