import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../UI/container/container";
import { WideBlock } from "../UI/wideBlock/wideBlock";
import { togglePopup } from "../popup/popup.js";
import { useAuth } from "../../features/auth/auth";
import Logo from '../../assets/img/Logo.svg';

import './header.scss';

export const Header = () => {
    const [Auth, setAuth] = useAuth();

    function toggleMenu() {
        document.querySelector('#menu').classList.toggle('__show');
    }

    function openRecall() {
        togglePopup("recall");
    }

    function openLogin() {
        togglePopup("login");
    }

    return (
        <header>
            <WideBlock>
                <p>Финансовая онлайн платформа<span> для бизнеса и физических лиц</span></p>
                <div className="header_call">
                    <a href="tel:+79991234567" className="header_phone">+7 999 123 45 67</a>
                    <button className="link_green" onClick={openRecall}>Заказать звонок</button>
                </div>
            </WideBlock>
            <Container>
                <a href="/"><img src={Logo} alt="Кредиты вместе" className="logo" /></a>
                <div id="menu" className="header_menu-wrapper">
                    <div className="header_menu">
                        <button className="menu_btn-mobile mb-close" onClick={toggleMenu}>
                            <span className="visually-hidden">Закрыть</span>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.915564 0.208581C0.720302 0.0133182 0.40372 0.0133182 0.208458 0.208581C0.0131963 0.403843 0.0131963 0.720426 0.208458 0.915689L9.79274 10.5L0.208532 20.0842C0.0132702 20.2795 0.01327 20.5961 0.208532 20.7914C0.403794 20.9866 0.720376 20.9866 0.915637 20.7914L10.4998 11.2071L20.0841 20.7914C20.2793 20.9866 20.5959 20.9866 20.7912 20.7914C20.9864 20.5961 20.9864 20.2795 20.7912 20.0842L11.2069 10.5L20.7912 0.915689C20.9865 0.720426 20.9865 0.403843 20.7912 0.208581C20.596 0.0133182 20.2794 0.0133182 20.0841 0.208581L10.4998 9.7929L0.915564 0.208581Z" fill="#626262" />
                            </svg>
                        </button>
                        <nav>
                            <ul>
                                <li><NavLink to='/' className={({ isActive }) => (isActive ? "nav_link _active" : "nav_link")}>Главная</NavLink></li>
                                <li><NavLink to='/кредиты' className={({ isActive }) => (isActive ? "nav_link _active" : "nav_link")}>Кредиты</NavLink></li>
                                <li><NavLink to='/гарантии' className={({ isActive }) => (isActive ? "nav_link _active" : "nav_link")}>Банковские гарантии</NavLink></li>
                                <li><NavLink to='/партнерам' className={({ isActive }) => (isActive ? "nav_link _active" : "nav_link")}>Партнерам</NavLink></li>
                                <li><NavLink to='/о_платформе' className={({ isActive }) => (isActive ? "nav_link _active" : "nav_link")}>О платформе</NavLink></li>
                            </ul>
                        </nav>
                        {Auth ? <NavLink to='личный_кабинет' className={({isActive}) => (isActive ? "btn_white-brdr" : "btn_green")}>Личный кабинет</NavLink> : <button className="btn_green" onClick={openLogin}>Личный кабинет</button>}
                        <button className="btn_white-brdr menu_btn-mobile">Заказать звонок</button>
                    </div>
                </div>
                <div className="header_btns-mobile">
                    <button className="header_btn-tab" onClick={openRecall}>
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.5505 17.4419L19.0156 17.0383C18.6 16.9895 18.1786 17.0355 17.7833 17.1729C17.388 17.3103 17.029 17.5355 16.7332 17.8316L14.1725 20.3923C10.2218 18.383 7.01057 15.1718 5.00128 11.221L7.57591 8.64635C8.17433 8.04792 8.46659 7.2129 8.36917 6.36397L7.96558 2.85689C7.88668 2.17799 7.56087 1.55181 7.05018 1.09757C6.53949 0.643339 5.87959 0.392775 5.19612 0.393587H2.78849C1.21588 0.393587 -0.092306 1.70178 0.00511234 3.2744C0.742708 15.1595 10.248 24.6509 22.1191 25.3885C23.6917 25.4859 24.9999 24.1777 24.9999 22.6051V20.1974C25.0138 18.7918 23.9561 17.6089 22.5505 17.4419Z" fill="#00A789" />
                        </svg>
                    </button>
                    {
                        Auth ?
                        <NavLink to='личный_кабинет' className="header_btn-tab">
                            <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.4999" cy="6.59559" r="6.59559" fill="#00A789" />
                                <path d="M0.343271 26.3545C0.91645 20.6227 5.73962 16.2578 11.5 16.2578C17.2604 16.2578 22.0835 20.6227 22.6567 26.3545L23 29.7872H0L0.343271 26.3545Z" fill="#00A789" />
                            </svg>
                        </NavLink>
                        :  
                        <button className="header_btn-tab" onClick={openLogin}>
                            <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.4999" cy="6.59559" r="6.59559" fill="#00A789" />
                                <path d="M0.343271 26.3545C0.91645 20.6227 5.73962 16.2578 11.5 16.2578C17.2604 16.2578 22.0835 20.6227 22.6567 26.3545L23 29.7872H0L0.343271 26.3545Z" fill="#00A789" />
                            </svg>
                        </button>
                    }
                    <button className="header_btn-mobile mb-open" onClick={toggleMenu}>
                        <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4">
                                <rect y="0.393585" width="40" height="5" fill="#626262" />
                                <rect y="10.3936" width="40" height="5" fill="#626262" />
                                <rect y="20.3936" width="40" height="5" fill="#626262" />
                            </g>
                        </svg>
                    </button>
                </div>
            </Container>
        </header>
    )
}