import React from "react";
import { Section } from "../../../components/UI/section/section";
import { ApplicationForm } from "../../../components/formApplication/applicationForm";
import { UL } from "../../../components/UI/unorderedList/unorderedList";
import { NavLink } from "react-router-dom";
import "./stepSection.css";

export const StepSection = () => {
    return(
        <Section className="step">
            <div className="step_banner">
                <h3>Так же мы предоставляем услуги по получению <span>банковской гарантии</span></h3>
                <UL>
                    <li><span>Удобное оформление заявки</span></li>
                    <li><span>Рассмотрение ТОП – Банков РФ</span></li>
                    <li><span>Комфортный срок рассмотрения заявки</span></li>
                </UL>
                <NavLink to="/гарантии" className="btn_green">Подробнее</NavLink>
            </div>
            <div className="step_content">
                <h2 className="ttl">Сделайте <span>первый шаг</span> уже сейчас</h2>
                <ApplicationForm />
            </div>
        </Section>
    )
}