import React, { useEffect, useState } from "react";
import { Section } from "../../../components/UI/section/section";
import { LogoutButton } from "../../../features/auth/auth";
import { AuthorizedFetch } from "../../../features/fetches/fetches";

import "./cabinetinfo.scss";
import { togglePopup } from "../../../components/popup/popup";

export const CabinetInfo = () => {
    const [Udata, setUdata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const link = process.env.REACT_APP_API_ROUTE + 'get-user-data/';
        (async () => {
            if (!isLoading) {
                AuthorizedFetch(link).then((result) => setUdata(result));
                setIsLoading(true);
            }
        })();
    }, [isLoading]);

    function handler() {
        togglePopup('change_pass')
    }

    return (
        <Section className="lkInfo">
            <h1 className="visually-hidden">Личный кабинет</h1>
            <h2 className="ttl">Здравствуйте, {Udata['fio']}</h2>
            <div className="lkInfo_btns">
                <button className="btn_white-brdr" onClick={handler}>Сменить пароль</button>
                <LogoutButton />
            </div>
        </Section>
    )
}