import React from "react";
import "./select.css";

export const Select = ( { children, name } ) => {

    return(
        <div className="select">
            <select name={name} className="input_text">
                { children }
            </select>
        </div>
    )
}