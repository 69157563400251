import React from "react";
import { Section } from "../../../components/UI/section/section";
import "./directions.css";

export const PlatformDirections = () => {

    return(
        <Section className="pDir">
            <h2>Основные направления цифровой платформы Кредит<span>Вместе</span></h2>
            <ul className="pDir_cards">
                <li><span className="">Кредитование</span></li>
                <li><span className="">Банковская гарантия</span></li>
            </ul>
        </Section>
    )
}