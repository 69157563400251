import React from "react";
import { Section } from "../../../components/UI/section/section";
import { FormFeedback } from "../../../components/formFeedback/formFeedback";
import "./feedback.css";

export const PlatformFeedback = () => {

    return(
        <Section className="pFeedback">
            <h2>Сделайте <span>первый шаг</span> уже сейчас</h2>
            <FormFeedback />
        </Section>
    )
}