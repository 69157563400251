import React, { createContext, useContext, useMemo, useState } from "react";
import { fitAuth, fitAuthJWT, fitAuthMail, fitJWTref, isAuth } from "../cookies/cookies";
import { Link } from "react-router-dom";

const AuthContext = createContext();

export function useAuth() {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error(`useAuth must be used within a AuthProvider`)
    }
    return context
}

export function AuthProvider(props) {
    const [Auth, setAuth] = useState(isAuth());
    const value = useMemo(() => [Auth, setAuth], [Auth])
    return <AuthContext.Provider value={value} {...props} />
}

export const LogoutButton = () => {
    const [Auth, setAuth] = useAuth();

    function handler() {
        setAuth(false);
        Logout();
    }

    return (
        <Link to={"/"} onClick={handler} className="btn_red" type="submit">Выйти</Link>
    )
}

export function Logout() {
    fitAuthMail('none');
    fitAuthJWT('none');
    fitJWTref('none');
    fitAuth(false);
}
