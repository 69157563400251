import React from "react";
import { HeroSection } from "./sectionHero/heroSection";
import { PartnersSection } from "../../components/partners/partnersSection";
import { CalculatorSection } from "./sectionCalculator/calculatorSection";
import { PlatformSection } from "./sectionPlatform/platformSection";
import { ExpertsSection } from "./sectionExperts/expertsSection";
import { FeedbackSection } from "./sectionFeedback/sectionFeedback";
import './home.css';

export const Home = () => {

    return (
        <main className="homepage">
            <HeroSection/>
            <PartnersSection/>
            <CalculatorSection/>
            <PlatformSection/>
            <ExpertsSection />
            <FeedbackSection />
        </main>
    )
}