import React from "react";
import "./popup.scss";

export function togglePopup(id) {
    document.querySelector("#popup_" + id).classList.toggle("_show-popup");
    // console.log(id);
}

export const PopUp = ({ ids, children }) => {
    
    function closePopup() {
        togglePopup(ids);
    } 

    return (
        <div className="popup_wrapper" id={"popup_" + ids}>
            <div className="popup">
                <button className="popup_btn-close" onClick={closePopup}>
                    <span className="visually-hidden">Закрыть</span>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.915564 0.208581C0.720302 0.0133182 0.40372 0.0133182 0.208458 0.208581C0.0131963 0.403843 0.0131963 0.720426 0.208458 0.915689L9.79274 10.5L0.208532 20.0842C0.0132702 20.2795 0.01327 20.5961 0.208532 20.7914C0.403794 20.9866 0.720376 20.9866 0.915637 20.7914L10.4998 11.2071L20.0841 20.7914C20.2793 20.9866 20.5959 20.9866 20.7912 20.7914C20.9864 20.5961 20.9864 20.2795 20.7912 20.0842L11.2069 10.5L20.7912 0.915689C20.9865 0.720426 20.9865 0.403843 20.7912 0.208581C20.596 0.0133182 20.2794 0.0133182 20.0841 0.208581L10.4998 9.7929L0.915564 0.208581Z" fill="#626262" />
                    </svg>
                </button>
                {children}
            </div>
        </div>
    )
}