// import React from "react";

function emailValidator(data) {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (EMAIL_REGEXP.test(data)) {
        return true
    } else {
        return false
    }
}

function innValidator(data) {
    const INN_REGEXP = /^(\d{10}|\d{12})$/;
    if (INN_REGEXP.test(data)) {
        return true
    } else {
        return false
    }
}

function phoneValidator(data) {
    const PHONE_REGEXP = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;
    if (PHONE_REGEXP.test(data)) {
        return true
    } else {
        return false
    }
}

function nameValidator(data) {
    const NAME_REGEXP = /^(?=.{1,40}$)[а-яёА-ЯЁ]+(?:[-' ][а-яёА-ЯЁ]+)*$/;
    if (NAME_REGEXP.test(data)) {
        return true
    } else {
        return false
    }
}

export function validateEmail(object) {
    // e.preventDefault();
    if (emailValidator(object.value)) {
        object.classList.remove("invalid");
        return true
    } else {
        object.classList.add("invalid");
        return false
    }
}
export function validateINN(object) {
    // e.preventDefault();
    if (innValidator(object.value)) {
        object.classList.remove("invalid");
        return true
    } else {
        object.classList.add("invalid");
        return false
    }
}

export function validatePhone(object) {
    // e.preventDefault();
    if (phoneValidator(object.value)) {
        object.classList.remove("invalid");
        return true
    } else {
        object.classList.add("invalid");
        return false
    }
}

export function validateName(object) {
    // e.preventDefault();
    if (nameValidator(object.value)) {
        object.classList.remove("invalid");
        return true
    } else {
        object.classList.add("invalid");
        return false
    }
}