import React from "react";
import { Section } from "../../../components/UI/section/section";
import { ApplicationForm } from "../../../components/formApplication/applicationForm";
import img from "../../../assets/img/illustration/garants_1.png";
import "./garantsSection.css";

export const GarantsSection = () => {
    return(
        <Section className="garants">
            <h1 className="ttl">Банковские гарантии — <span>удобный</span> способ получить <span>надежное</span> финансирование </h1>
            <ApplicationForm />
            <img src={img} alt="" />
        </Section>
    )
}