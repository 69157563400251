import React from "react";
import { Section } from "../../../components/UI/section/section";
import img from "../../../assets/img/illustration/platform4.png"
import "./individuality.css";

export const PlatformIndiv = () => {

    return(
        <Section className="pInd">
            <div className="pInd_info">
                <h2><span>Индивидуальный</span> подход</h2>
                <div className="pInd_txt">
                    <p>Понимаем, что каждый клиент уникален, поэтому окончательные условия по кредиту формируются с учетом различных факторов, включая финансовое положение заемщика, возраст, семейное положение, уровень финансовой ответственности и другие обстоятельства. Мы стремимся подобрать гибкие решения, которые наилучшим образом отвечают вашим потребностям</p>
                    <p>В "КредитВместе" окончательное решение по выдаче финансирования всегда принимается на основе предоставленных оригиналов документов и с соблюдением кредитной политики партнеров. Наша команда кредитных специалистов плотно сотрудничает с вами, обеспечивая прозрачность и надежность в процессе рассмотрения заявки на выбранный продукт</p>
                    <p>Мы стремимся учесть каждую деталь вашей финансовой ситуации и предложить оптимальные условия кредитования.</p>
                    <p>В "КредитВместе" мы готовы помочь вам реализовать ваши финансовые цели и обеспечить успешное развитие вашего бизнеса</p>
                </div>
            </div>
            <img src={img} className="pInd_img" alt=""/>
        </Section>
    )
}