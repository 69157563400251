import React, { useState } from "react";
import { PopUp, togglePopup } from "../../../components/popup/popup";
import { Spinner } from "../../../components/UI/spinner/spinner";
import { serializeFD } from "../../../features/forms/forms";
import { useToast } from "../../../features/Toast/useToast";

import "./passPopup.css";
import { AuthorizedFetch, changePassFetch } from "../../../features/fetches/fetches";

export const PassPopup = () => {
    const [Loaded, setLoaded] = useState(true)
    const link = process.env.REACT_APP_API_ROUTE + 'change-password/';
    // const data = {'old_password': val, 'new_password': val}

    const toast = useToast();
    const successToast = () => toast.open(`Пароль изменен успешно!`, "success");
    const errorToast = () => toast.open(`Ошибка!`, "error");
    const errorToastDiff = () => toast.open(`Ошибка! Неверный пароль!`, "error");
    const warningToastRepeat = () => toast.open(`Новый пароль должен совпадать с повтором!`, "warning");
    const warningToastCopy = () => toast.open(`Новый пароль должен отличаться!`, "warning");

    function handleChange(e) {
        e.preventDefault()
        const form = e.target;
        const fdata = new FormData(form);
        const data = serializeFD(fdata);
        const request_data = {
            'old_password' : data['old_password'],
            'new_password' : data['new_password']
        }

        function toggler() {
            form.querySelectorAll('input').forEach(element => {
                element.classList.toggle('invalid');
            });
        }
        if (data['old_password'] === data['new_password']) {
            toggler();
            setTimeout(toggler, 2000);
            errorToast();
            warningToastCopy();
        } else if (data['new_password'] !== data['new_password_rep']) {
            toggler();
            setTimeout(toggler, 2000);
            errorToast();
            warningToastRepeat();
        } else {
            setLoaded(false);
            changePassFetch(link, request_data).then((response) => {
                if (!response.ok) {
                    setLoaded(true);
                    toggler();
                    setTimeout(toggler, 2000);
                    errorToastDiff();
                    return response.json()
                } else {
                    togglePopup('change_pass');
                    setLoaded(true);
                    successToast();
                }
            })
        }
    }

    return (
        <PopUp ids="change_pass">
            <h2 className="ttl">Сменить пароль</h2>
            {Loaded ?
                <form onSubmit={handleChange}>
                    <label>
                        <span className="visually-hidden">Поле ввода старого пароля</span>
                        <input id="pass_old" className="input_text" type="password" placeholder="Старый пароль" name="old_password" />
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода нового пароля</span>
                        <input id="pass_new" className="input_text" type="password" placeholder="Новый пароль" name="new_password" />
                    </label>
                    <label>
                        <span className="visually-hidden">Поле ввода повтора нового пароля</span>
                        <input id="pass_rep" className="input_text" type="password" placeholder="Повтор пароля" name="new_password_rep" />
                    </label>
                    <button className="btn_green" type="submit">Сменить пароль</button>
                </form>
                : <Spinner />}
        </PopUp>
    )
}