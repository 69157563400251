import React from "react";
import { PlatformHero } from "./hero/hero";
import { PlatformBanks } from "./banks/banks";
import { PlatformTeam } from "./team/team";
import { PlatformDirections } from "./directions/directions";
import { PlatformIndiv } from "./individuality/individuality";
import { PlatformUget } from "./uget/uget";
import { PlatformFeedback } from "./feedback/feedback";

export const Platform = () => {

    return(
        <main>
            <PlatformHero />
            <PlatformBanks />
            <PlatformTeam />
            <PlatformDirections />
            <PlatformIndiv />
            <PlatformUget />
            <PlatformFeedback />
        </main>
    )
}