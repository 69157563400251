import React from "react";
import { Section } from "../../../components/UI/section/section";
import { Calculator } from "../../../components/calculator/calculator";
import './calculatorSection.css';

export const CalculatorSection = () => {
    return(
        <Section className="calculator_section">
            <h2 className="ttl">Калькулятор</h2>
            <Calculator />
        </Section>
    )
}