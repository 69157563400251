import React from "react";
import { Section } from "../../../components/UI/section/section";
import { OL } from "../../../components/UI/orderedList/orderedList";

export const HowSection = () => {
    return(
        <Section>
            <h2 className="ttl">Что нужно для получения финансирования?</h2>
            <OL>
                <li><span>Регистрация</span></li>
                <li><span>Оформление заявки</span></li>
                <li><span>Решение</span></li>
            </OL>
        </Section>
    )
}